import {FirebaseOptions} from 'firebase/app';
import packageJson from '../../package.json';

export const version = packageJson.version;

export const sentryDsn =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const isDev = process.env.NEXT_PUBLIC_NODE_ENV === 'development';

export const environment = process.env.NEXT_PUBLIC_NODE_ENV || 'development';

export const release = 'bt-web@' + version;

export const firebaseConfig: FirebaseOptions = {
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
};

export const firebaseVapidKey = process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY;

export const siteURL =
  process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000';
